import React from 'react';
import { withLocaleRequired } from '@gasket/react-intl';
import { injectIntl } from 'react-intl';

import NotFound from '../components/not-found';

const Custom404 = () => {
  return <NotFound />;
};

export default withLocaleRequired()(injectIntl(Custom404));
