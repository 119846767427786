import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AlertIcon from '@ux/icon/alert';
import Button from '@ux/button';

import intents from '../common/intents-config';

const NotFound = () => {
  const intl = useIntl();

  return (
    <div
      data-testid="not-found"
      className="container d-flex justify-content-center"
    >
      <div className="bg-white shadow-1 rounded col-sm-10 mt-5 mb-4 justify-content-center text-center flex-column p-4 error">
        <div id="custom-error" className="p-2">
          <AlertIcon
            color={intents.ux.actionCritical.backgroundColor}
            width={70}
            height={70}
          />
          <h2 className="mt-4 mb-4 leading-1">
            <FormattedMessage id="common_page_not_found" />
          </h2>
          <div className="mt-4 px-5">
            <p className="mb-4 mt-4 p-base">
              <FormattedMessage id="common_error_code" />: 404
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center mt-4">
            <div className="d-flex justify-content-center">
              <Button
                href="/"
                text={intl.formatMessage({ id: 'common_go_back_home' })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
